$hyoo_todomvc $mol_scroll
	title \Todos
	attr *
		^
		mol_theme \$mol_theme_light
	sub /
		<= Page $mol_list rows /
			<= Title $mol_paragraph
				minimal_height 176
				title <= title
			<= Panel $mol_list
				rows <= panels /
					<= Head $mol_view
						minimal_height 64
						sub <= Head_content /
							<= Head_complete $mol_check
								enabled <= head_complete_enabled false
								checked? <=> completed_all? false
								title \❯
							<= Add $mol_string
								hint \What needs to be done?
								value? <=> task_title_new? \
								submit? <=> add? null
					<= List $mol_list rows <= task_rows /$mol_view
						<= Task_row*0 $hyoo_todomvc_task_row
							completed? <=> task_completed*? false
							title? <=> task_title*? \
							drop? <=> task_drop*? null
					<= Foot $mol_view sub <= foot_content /
						<= Pending $mol_paragraph title <= pending_message \0 items left 
						<= Filter $mol_bar
							sub <= filterOptions /
								<= Filter_all $mol_link
									title \All
									arg * completed null
								<= Filter_active $mol_link
									title \Active
									arg * completed \false
								<= Filter_completed $mol_link
									title \Completed
									arg * completed \true
						<= Sweep $mol_button_minor
							enabled <= sweep_enabled true
							click? <=> sweep? null
							sub / <= sweep_label \Clear completed

$hyoo_todomvc_task_row $mol_view
	minimal_height 64
	attr *
		^
		hyoo_todomvc_task_row_completed <= completed? false
	sub /
		<= Complete $mol_check
			checked? <=> completed? false
		<= Title $mol_string
			hint <= title_hint \Task title
			value? <=> title? \
		<= Drop $mol_button_typed
			sub / \✖
			click? <=> drop? null
